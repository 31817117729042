$env: production;
.subscription-card {
  h3 {
    font-size: 2.5rem;
    margin: 20px 0;
  }
  span {
    font-size: 1.125rem;
  }
  .brand span {
    padding: 0 20px;
    background: #D5D5D5;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
  }
  .card {
    border: 2px solid #D1D1D1;
    border-radius: 28px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 100%;

    &:hover {
      border-color: #0E54EA;
    }
  }
  .card-header {
    background-color: #F5F5F5;
    text-align: center;
    border-radius: 28px 28px 0 0;
    padding: 18px 0 9px 0;
  }
  .card-body {
    padding: 30px 43px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .plan-toggle {
    min-width: 202px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 28px;
    border: 3px solid #fff;
    text-align: center;

    a {
      padding: 5px;
      font-size: 1rem;
      border-radius: 28px;
    }

    li {
      float: none;
      width: 50%;

      a.active {
        background: #0E54EA;
        color: #FFFFFF;
      }
    }
  }
  .card-body {
    ul {
      margin-left: 10px;
      text-align: left;
    }

    li {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 11px;

      &::before {
        content: "\f058";
        font-family: "Font Awesome 5 Free";
        color: #0E54EA;
        font-size: 17px;
        margin-right: 10px;
      }
    }
    .btn {
      margin-top: auto;
    }
  }
}

.subscription-card-wrapper{
  > div {
    margin: 15px auto;
  }
} 